import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { User } from '../../../../business'
import * as a9 from '../../../../analytics'
import { useTexts } from '../../../../texts'
import { AUTHENTICATED_DEFAULT_ROUTE } from '../../../constants'
import { CircularProgress } from '../../../components'
import View from './View'

const B2B_PAYPER_WEB = 'https://www.bepayper.com/'

const openUrl = ({ url, target = '_self' }) => {
  window.open(url, target, {
    rel: 'noreferrer noopener',
  })
}

const DEFAULT_CONTENT = (texts) => {
  const label = texts.endOfServiceActionLabelDefault()
  const url = B2B_PAYPER_WEB

  return {
    text1: texts.endOfServiceText1Default(),
    text2: texts.endOfServiceText2Default(),
    action: {
      label,
      onClick: () => {
        a9.dispatchEvent(a9.EVENTS.PD_CLICK_EOS)
        openUrl({ url })
      },
    },
  }
}

export const getContentByCustomer = (customer, texts) => {
  if (!Boolean(customer)) return DEFAULT_CONTENT(texts)

  let content = {}
  content.text1 = texts.endOfServiceText1Customer(customer?.name)
  content.text2 = customer?.configuration?.endOfService?.claim
  if (!!customer?.configuration?.endOfService?.cta) {
    const { label, url } = customer?.configuration?.endOfService?.cta
    content.action = {
      label,
      onClick: () => {
        a9.dispatchEvent(a9.EVENTS.PD_CLICK_EOS, {
          customerId: customer.id,
        })
        openUrl({ url })
      },
    }
  }
  return content
}

const EndOfService = () => {
  const texts = useTexts()
  const navigate = useNavigate()

  const { isLoading, data } = User.useLicence()

  useEffect(() => {
    if (!isLoading && !data?.isEndOfService) {
      navigate(AUTHENTICATED_DEFAULT_ROUTE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data])

  if (isLoading) return <CircularProgress height='80vh' />

  return (
    <View
      customer={data.customer}
      {...getContentByCustomer(data.customer, texts)}
      onLoad={() => {
        a9.dispatchEvent(a9.EVENTS.PD_PAGE_VIEW_EOS, {
          customerId: data.customer?.id,
        })
      }}
    />
  )
}

export default EndOfService
