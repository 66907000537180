import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Session } from '../business'
import { AUTHENTICATED_DEFAULT_ROUTE } from './constants'
import { getSearchParamValue, SEARCH_PARAMS } from './searchParams'
import { CircularProgress } from './components'

const RequireNotAuthenticated = ({ children }) => {
  let location = useLocation()
  const navigate = useNavigate()

  const [isRefreshingSession, setIsRefreshingSession] = useState(null)

  Session.storeSearchParam(Session.STORED_URL_PARAMS.USER_LICENCE_TOKEN)

  useEffect(() => {
    ;(async () => {
      if (isRefreshingSession === null) {
        setIsRefreshingSession(true)

        if (Session.isAuthenticated()) {
          if (Session.shouldBeRefreshed()) {
            try {
              await Session.refreshSession()
            } catch (error) {
              console.error(error)
              Session.logout()
            }
          }
          const redirectUriValue = getSearchParamValue(
            SEARCH_PARAMS.REDIRECT_URI,
          )
          if (redirectUriValue) {
            window.location = `${redirectUriValue}?${new URLSearchParams(
              Session.getAccessParams(),
            ).toString()}`
          } else {
            navigate(AUTHENTICATED_DEFAULT_ROUTE, {
              state: { from: location },
              replace: true,
            })
          }
        } else {
          setIsRefreshingSession(false)
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isRefreshingSession !== false) return <CircularProgress />

  return children
}

export default RequireNotAuthenticated
