import { Box } from '@mui/material'

const styles = {
  root: {
    width: '100%',
    height: (theme) => theme.spacing(7.625),
    borderRadius: (theme) => ({
      md: theme.spacing(0.5),
    }),
    px: 4,
    // display: 'grid',
    // placeItems: 'center',
    // NOTE: placeItems, align-items and justify-items, is not well-supported in all iPhone browsers
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '> img': {
      display: 'block',
      maxWidth: (theme) => `min(100%, ${theme.spacing(20)})`,
      maxHeight: (theme) => `min(80%, ${theme.spacing(6)})`,
      height: 'auto',
      width: 'auto',
    },
  },
}

const HeroLogo = ({
  imageUrl,
  name,
  configuration,
  isHeader,
  sx,
  className,
}) => {
  let customStyles = {}
  if (Boolean(configuration?.backgroundColor)) {
    customStyles = {
      ...customStyles,
      backgroundColor: configuration.backgroundColor,
    }
  }
  if (!Boolean(configuration?.backgroundColor) && isHeader) {
    customStyles = { ...customStyles, pt: { xs: 2, md: 3 } }
  }

  return (
    <Box
      component='picture'
      sx={{
        ...styles.root,
        ...sx,
        ...customStyles,
      }}
      className={className}
      data-test='hero-logo'
    >
      <img src={imageUrl} alt={`${name} logo`} />
    </Box>
  )
}

export default HeroLogo
