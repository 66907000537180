import { useNavigate } from 'react-router-dom'
import { Box, Button } from '@mui/material'

import { PageBlock } from '../../../Layout'
import { Public } from '../../../../business'
import { useTexts } from '../../../../texts'

const styles = {
  buttonsContainer: (theme) => ({
    width: 'min(100%, var(--content-L-max-width))',
    mx: 'auto',
    px: (theme) => ({
      xs: theme.spacing(0),
      md: theme.spacing(3),
      lg: theme.spacing(8, 4, 9),
      xl: theme.spacing(8, 8.75, 9),
    }),

    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: (theme) => theme.spacing(1),
    gridRowGap: (theme) => theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridColumnGap: (theme) => theme.spacing(2),
    },
  }),
  button: {
    backgroundColor: 'neutral.white',
    boxShadow: 2,
    '&:hover': {
      backgroundColor: 'neutral.light',
      boxShadow: 2,
    },
    '&:active': {
      boxShadow: 0,
    },

    height: 50,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    p: (theme) => ({
      xs: theme.spacing(0.5),
      lg: theme.spacing(1),
    }),
  },
}

const Sections = () => {
  const texts = useTexts()
  const navigate = useNavigate()

  const sections = Public.useArticlesSections()

  const navigateToSection = (sectionId) => {
    navigate(`section/${sectionId}`)
  }

  if (sections.isLoading || !sections.data) {
    return null
  }
  return (
    <PageBlock title={texts.getSectionsTitle()}>
      <Box sx={styles.buttonsContainer} id='section_buttons'>
        {Object.entries(sections.data).map(([sectionKey, sectionLabel]) => (
          <Button
            key={sectionKey}
            onClick={() => navigateToSection(sectionKey)}
            sx={styles.button}
          >
            {sectionLabel}
          </Button>
        ))}
      </Box>
    </PageBlock>
  )
}

export default Sections
