import React from 'react'
import { Box } from '@mui/material'

import { Session, User } from '../../../business'
import { Footer } from '../../components'
import ScrollRevisionOnPageChange from '../../ScrollRevisionOnPageChange'
import Navigation from './Navigation'
import styles from './mainPage.styles'

const ROOT_ID = 'root-node'

export const MainPageContentLayout = ({ children, sx = {} }) => (
  <Box component='main' sx={{ ...styles.main, ...sx }}>
    {children}
  </Box>
)

export const MainPageLayoutView = ({
  children,
  withoutFooter,
  withoutHeader,
  isPublic,
}) => {
  return (
    <>
      <Navigation withoutHeader={withoutHeader} isPublic={isPublic}>
        <Box sx={styles.root} id={ROOT_ID}>
          {children}

          {!withoutFooter && <Footer isPublic={isPublic} />}
        </Box>
      </Navigation>
    </>
  )
}

const MainPageLayout = ({ children, withoutFooter, withoutHeader }) => {
  const isPublic = !Session.isAuthenticated()

  const DataProviders = isPublic ? React.Fragment : User.PersonalDataProvider

  return (
    <DataProviders>
      <ScrollRevisionOnPageChange>
        <MainPageLayoutView
          withoutFooter={withoutFooter}
          withoutHeader={withoutHeader}
          isPublic={isPublic}
        >
          {children}
        </MainPageLayoutView>
      </ScrollRevisionOnPageChange>
    </DataProviders>
  )
}

export default MainPageLayout
