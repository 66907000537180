import jwtDecode from 'jwt-decode'

import { Session } from '../../../business'
import ACCESS_PARAMS from './ACCESS_PARAMS'

const UTM_ATTRIBUTION_PARAMS = {
  UTM_ID: 'utm_id',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_MEDIUM: 'utm_medium',
  UTM_SOURCE: 'utm_source',
  UTM_TERM: 'utm_term',
  UTM_CONTENT: 'utm_content',
}

const getSearchParamsByType = (paramsMap) => {
  const searchParams = new URLSearchParams(window.location.search)
  let paramsObj = {}
  for (const param of Object.values(paramsMap)) {
    if (searchParams.get(param) !== null) {
      paramsObj[param] = searchParams.get(param)
    }
  }
  return paramsObj
}

export const getLicenceIdFromUrl = () => {
  try {
    const params = new URLSearchParams(window.location.search)
    const userLicToken = params.get(ACCESS_PARAMS.USER_LICENCE_TOKEN)
    if (userLicToken === null) {
      return null
    }
    const decodedToken = jwtDecode(userLicToken)
    return Boolean(decodedToken?.id) ? decodedToken.id : null
  } catch (e) {
    return null
  }
}

const getAttributionParams = () => {
  return {
    utm: getSearchParamsByType(UTM_ATTRIBUTION_PARAMS),
    licence_id: getLicenceIdFromUrl(),
  }
}

const getRedirectOnAuthUri = (baseUri) => {
  if (!baseUri) {
    return null
  }
  const sessionParams = new URLSearchParams(Session.getAccessParams())
  return `${baseUri}?${sessionParams.toString()}`
}

const useAccessParams = () => {
  const accessParams = getSearchParamsByType(ACCESS_PARAMS)

  return {
    accessParams,
    attribution: getAttributionParams(),
    redirectOnAuthUri: getRedirectOnAuthUri(
      accessParams?.[ACCESS_PARAMS.REDIRECT_URI],
    ),
    redirectOnUnauthUri:
      accessParams?.[ACCESS_PARAMS.REDIRECT_ON_UNAUTH_URI] ??
      accessParams?.[ACCESS_PARAMS.REDIRECT_URI],
    hasLicenceCreationToken: Boolean(
      accessParams[ACCESS_PARAMS.USER_LICENCE_TOKEN],
    ),
  }
}

export default useAccessParams
