import PropTypes from 'prop-types'
import { NAVIGATION_ITEMS } from './constants'

export const AssociatedMediaData = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  domain: PropTypes.string,
})

export const Article = PropTypes.shape({
  articleId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  mediaProviderId: PropTypes.string,
  image: PropTypes.string,
  mediaName: PropTypes.string,
  mediaIconUrl: PropTypes.string,
})

export const ArticlesSection = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.arrayOf(Article),
})

export const EmailValidation = PropTypes.shape({
  regExpPattern: PropTypes.string.isRequired,
  regExpFlags: PropTypes.string,
  message: PropTypes.string,
})

export const NavigationItemName = PropTypes.oneOf(
  Object.values(NAVIGATION_ITEMS),
)
export const NavigationActions = PropTypes.arrayOf(
  PropTypes.shape({
    name: NavigationItemName.isRequired,
    icon: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
)

export const Customer = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  configuration: PropTypes.shape({
    claim: PropTypes.string,
    claimAccess: PropTypes.string,
    emailValidation: EmailValidation,
  }),
})

export const UserLicence = PropTypes.shape({
  id: PropTypes.number,
  licenceId: PropTypes.string,
  isActive: PropTypes.bool,
  expirationDate: PropTypes.string,
})

export const UserLicenceData = PropTypes.shape({
  customer: Customer,
  licence: UserLicence,
})

export const UserLicenceState = PropTypes.shape({
  data: UserLicenceData,
  error: PropTypes.bool,
  isLoading: PropTypes.bool,
})

export const UserData = PropTypes.shape({
  email: PropTypes.string.isRequired,
  name: PropTypes.string,
  familyName: PropTypes.string,
  phone: PropTypes.string,
  communicationsAccepted: PropTypes.bool,
})

export const BalanceData = PropTypes.shape({
  data: PropTypes.string,
  error: PropTypes.bool,
  isLoading: PropTypes.bool,
})

export const BankCard = PropTypes.shape({
  cardNumber: PropTypes.string,
  createdAt: PropTypes.string,
  expiryDate: PropTypes.string,
  id: PropTypes.number,
  isExpired: PropTypes.bool,
  merchantIdentifier: PropTypes.string,
})
export const BankCardData = PropTypes.shape({
  data: BankCard,
  error: PropTypes.bool,
  isLoading: PropTypes.bool,
})

export const Topic = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  emoji: PropTypes.string,
  similarityThreshold: PropTypes.number,
  timeInterval: PropTypes.number,
})
