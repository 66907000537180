import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  CircularProgress,
  InputAdornment,
  Box,
  Link as MUILink,
  IconButton,
  Button,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import {
  Alert,
  FormSkinWrapper,
  TextField,
  Checkbox,
  PrivacyPolicySummary,
  ExpandableContent,
  HelperText,
} from '../../../../components'
import { Icons } from '../../../../../assets'
import { useTexts } from '../../../../../texts'
import styles from '../access.styles.js'

const userDataStepViewPropTypes = {
  email: PropTypes.string.isRequired,
  withGeneratedPassword: PropTypes.bool,
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  ctaLabel: PropTypes.string.isRequired,
  onChangeControlledLegalCheckbox: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    name: PropTypes.string,
    familyName: PropTypes.string,
    email: PropTypes.string,
    conditionsAccepted: PropTypes.string,
    communicationsAccepted: PropTypes.string,
  }).isRequired,
  formError: PropTypes.string.isRequired,
  fieldErrors: PropTypes.shape({
    name: PropTypes.string,
    familyName: PropTypes.string,
    email: PropTypes.string,
    conditionsAccepted: PropTypes.string,
  }).isRequired,
  onChangeEmail: PropTypes.func.isRequired,
}

const UserDataStepView = ({
  email,
  withGeneratedPassword,
  formId,
  onSubmit,
  isSubmitting,
  ctaLabel,
  onChangeControlledLegalCheckbox,
  formValues,
  formError,
  fieldErrors,
  onChangeEmail,
}) => {
  const texts = useTexts()

  const [showPassword, setShowPassword] = useState(false)
  const [showPrivacyPolicySummary, setShowPrivacyPolicySummary] =
    useState(false)

  return (
    <>
      <Typography id='dialogTitle' sx={styles.heading}>
        {texts.getUserDataTitle()}
      </Typography>
      <form id={formId} onSubmit={onSubmit} noValidate>
        <FormSkinWrapper skin='light' sx={styles.form}>
          <TextField
            data-test='email-input'
            id='email'
            name='email'
            defaultValue={email}
            label={texts.getLoginFormNameLabel()}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position='start'>
                  <Icons.Envelope />
                </InputAdornment>
              ),
            }}
            inputProps={{ inputMode: 'email' }}
            required
          />
          <Box sx={styles.groupFields}>
            <TextField
              data-test='name-input'
              id='name'
              name='name'
              label={texts.getNameLabel()}
              autoComplete='given-name'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Icons.User2 />
                  </InputAdornment>
                ),
              }}
              error={Boolean(fieldErrors.name)}
              required
            />
            <TextField
              data-test='family-name-input'
              id='familyName'
              name='familyName'
              label={texts.getFamilyNameLabel()}
              autoComplete='family-name'
              error={Boolean(fieldErrors.familyName)}
              required
            />
            {fieldErrors.name || fieldErrors.familyName ? (
              <Box sx={styles.groupErrors}>
                <Icons.ExclamationTriangle />
                {fieldErrors.name || fieldErrors.familyName}
              </Box>
            ) : null}
          </Box>
          {!withGeneratedPassword && (
            <>
              <TextField
                id='password'
                name='password'
                data-test='password-input'
                label={texts.getLoginFormPasswordLabel()}
                autoComplete='current-password'
                type={showPassword ? 'text' : 'password'}
                helperText={fieldErrors.password}
                error={Boolean(fieldErrors.password)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Icons.LockClosed />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position='end'
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      <IconButton aria-label={texts.getTogglePasswordAction()}>
                        {showPassword ? (
                          <Icons.EyeFilled />
                        ) : (
                          <Icons.EyeClosed />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
              />
              <Box sx={{ mb: 0.5, mt: -0.5 }}>
                <HelperText
                  text={texts.getRegisterPasswordRequirementsLabel()}
                />
              </Box>
            </>
          )}
          <TextField
            id='phone'
            name='phone'
            label={texts.getPhoneLabel()}
            autoComplete='tel'
            type='tel'
            helperText={fieldErrors.phone}
            error={Boolean(fieldErrors.phone)}
            // NOTE: this field has been hidden temporarily due to a business decision
            sx={{ display: 'none' }}
          />
          {Boolean(formError) ? (
            <Alert variant='error' text={formError} />
          ) : null}
          <LoadingButton
            data-test='submit-btn'
            type='submit'
            color='primary'
            loading={isSubmitting}
            disabled={isSubmitting}
            loadingIndicator={<CircularProgress size={16} />}
            fullWidth
            sx={styles.cta}
            endIcon={<Icons.ArrowRight />}
          >
            {ctaLabel ?? texts.getRegisterContinueAction()}
          </LoadingButton>

          <Box id='legal-checks'>
            <Box sx={styles.collapsible}>
              <Checkbox
                data-test='legal-agreement-checkbox'
                skin='light'
                label={
                  <>
                    {texts.getLegalAgreementLabel1()}
                    <MUILink
                      href='https://www.bepayper.com/terminos-y-condiciones'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {texts.getLegalAgreementTermsLabel()}
                    </MUILink>
                    {texts.getAndLabel()}
                    <MUILink
                      href='https://www.bepayper.com/politica-de-privacidad'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {texts.getLegalAgreementPrivacyPolicyLabel()}
                    </MUILink>
                    {texts.getLegalAgreementLabel2()}
                  </>
                }
                name='conditionsAccepted'
                checked={Boolean(formValues.conditionsAccepted)}
                onChange={onChangeControlledLegalCheckbox}
                error={
                  fieldErrors.conditionsAccepted &&
                  texts.getErrorConditionsAccepted()
                }
                required
              />
              <IconButton
                data-test='privacy-policy-toggle'
                aria-label={texts.getCloseAction()}
                onClick={() => setShowPrivacyPolicySummary((value) => !value)}
                sx={styles.collapsibleButton}
              >
                {showPrivacyPolicySummary ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            </Box>
            <ExpandableContent expanded={showPrivacyPolicySummary}>
              <PrivacyPolicySummary />
            </ExpandableContent>
            <Checkbox
              data-test='communications-accepted-checkbox'
              label={texts.getLegalAgreementCommunicationsLabel()}
              name='communicationsAccepted'
              checked={Boolean(formValues.communicationsAccepted)}
              onChange={onChangeControlledLegalCheckbox}
              // sx={{ mt: 1 }}
              // NOTE: this field has been hidden temporarily due to a business decision
              sx={{ display: 'none' }}
            />
          </Box>

          <Button
            data-test='change-email-btn'
            id='change-email-btn'
            color='secondary'
            onClick={onChangeEmail}
            fullWidth
          >
            {texts.getChangeEmailAction()}
          </Button>
        </FormSkinWrapper>
      </form>
    </>
  )
}

UserDataStepView.propTypes = userDataStepViewPropTypes

export default UserDataStepView
