import jwtDecode from 'jwt-decode'

import * as sp from '../App/searchParams'

import {
  login as loginService,
  logout as logoutService,
  refreshSession as refreshSessionService,
} from './auth'

const URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS = {
  REFRESH_TOKEN: 'p_token',
  EMAIL: 'p_email',
}

const ID_TOKEN_STORAGE_KEY = 'payper_id_token'
const ID_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY =
  'payper_id_token_expiration_timestamp'
const ACCESS_TOKEN_STORAGE_KEY = 'payper_access_token'
const ACCESS_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY =
  'payper_access_token_expiration_timestamp'
const REFRESH_TOKEN_STORAGE_KEY = 'payper_refresh_token'

const HIDE_WIZARD_KEY = 'payper_hide_home_screen_wizard'

export const STORED_URL_PARAMS = {
  USER_LICENCE_TOKEN: 'ul',
}
export const URL_PARAM_STORAGE_MAP = {
  [STORED_URL_PARAMS.USER_LICENCE_TOKEN]: 'payper_ul',
}

export const setSessionTokens = (tokens) => {
  window.localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, tokens.refreshToken)
  window.localStorage.setItem(ID_TOKEN_STORAGE_KEY, tokens.idToken)
  window.localStorage.setItem(
    ID_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY,
    JSON.stringify(tokens.idTokenExpiresAt),
  )
  window.localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, tokens.accessToken)
  window.localStorage.setItem(
    ACCESS_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY,
    JSON.stringify(tokens.accessTokenExpiresAt),
  )
}
export const storeSession = (tokens) => {
  setSessionTokens(tokens)
  document.dispatchEvent(new Event('sessionStored'))
}

export const clearSessionDataStorage = () => {
  window.localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY)
  window.localStorage.removeItem(ID_TOKEN_STORAGE_KEY)
  window.localStorage.removeItem(ID_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY)
  window.localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY)
  window.localStorage.removeItem(ACCESS_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY)

  window.localStorage.removeItem(HIDE_WIZARD_KEY)
}

export const clearStorage = () => {
  clearSessionDataStorage()
  window.localStorage.removeItem(
    URL_PARAM_STORAGE_MAP[STORED_URL_PARAMS.USER_LICENCE_TOKEN],
  )
}

export const getHideWizardFlag = () =>
  window.localStorage.getItem(HIDE_WIZARD_KEY)
export const setHideWizardFlag = (value) =>
  window.localStorage.setItem(HIDE_WIZARD_KEY, value)

export const getAccessTokenExpiration = () =>
  window.localStorage.getItem(ACCESS_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY)

export const getIdToken = () =>
  window.localStorage.getItem(ID_TOKEN_STORAGE_KEY)

export const getAccessToken = () =>
  window.localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)

export const getRefreshToken = () =>
  window.localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY)

export const isAuthenticated = () => !!getRefreshToken() && !!getAccessToken()

const isTokenExpired = (tokenExpiration) => {
  const nowTimestampInSecs = new Date().getTime() / 1000
  return nowTimestampInSecs > tokenExpiration
}

const EXPIRATION_PREVENTIVE_TIME_SPAN_IN_SECS = 15 * 60
const shouldTokenBeReIssued = (tokenExpiration) => {
  const nowTimestampInSecs = new Date().getTime() / 1000
  const limitTimestampForValidToken =
    nowTimestampInSecs + EXPIRATION_PREVENTIVE_TIME_SPAN_IN_SECS
  return tokenExpiration < limitTimestampForValidToken
}

export const hasValidAccessToken = () =>
  !isTokenExpired(getAccessTokenExpiration())

export const shouldBeRefreshed = () =>
  shouldTokenBeReIssued(getAccessTokenExpiration())

export const getUserInfoFromIdToken = () => {
  if (!Boolean(getIdToken())) return null
  return jwtDecode(getIdToken())
}

export const getUserSub = () => {
  return getUserInfoFromIdToken()?.sub ?? null
}

export const getIs3rdPartyIdpAccount = () => {
  const userInfo = getUserInfoFromIdToken()
  return Boolean(userInfo?.identities?.length || !!userInfo?.['custom:sso'])
}

export const login = async (username, password) => {
  const userData = await loginService(username, password)
  storeSession(userData)
  return getUserSub()
}

export const logout = async () => {
  const refreshToken = getRefreshToken()

  await logoutService(refreshToken)
  clearStorage()
  window.location.reload()
}

export const refreshSession = async () => {
  const { email } = getUserInfoFromIdToken()
  const tokens = await refreshSessionService(email, getRefreshToken())
  if (tokens) {
    storeSession(tokens)
  } else {
    logout()
  }
  return tokens
}

export const getAccessParams = () => {
  return {
    [URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS.REFRESH_TOKEN]: getRefreshToken(),
    [URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS.EMAIL]:
      getUserInfoFromIdToken()?.email,
  }
}

export const storeSearchParam = (searchParamName) => {
  if (!!URL_PARAM_STORAGE_MAP[searchParamName]) {
    const searchParamValue = sp.getSearchParamValue(searchParamName)
    if (searchParamValue !== null) {
      window.localStorage.setItem(
        URL_PARAM_STORAGE_MAP[searchParamName],
        searchParamValue,
      )
    }
  }
}

export const readStoredSearchParam = (searchParamName) => {
  let storedParamValue
  const storedParamName = URL_PARAM_STORAGE_MAP[searchParamName]
  if (!!storedParamName) {
    storedParamValue = window.localStorage.getItem(storedParamName)
    window.localStorage.removeItem(storedParamName)
    sp.removeSearchParamFromURL(searchParamName)
  }
  return storedParamValue
}

export const isSearchParamStored = (searchParamName) => {
  let storedParamValue
  const storedParamName = URL_PARAM_STORAGE_MAP[searchParamName]
  if (!!storedParamName) {
    storedParamValue = window.localStorage.getItem(storedParamName)
  }
  return Boolean(storedParamValue)
}
