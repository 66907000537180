import PropTypes from 'prop-types'
import { Typography, Box } from '@mui/material'

import styles from './chip.styles.js'

const chipPropTypes = {
  variant: PropTypes.oneOf([
    'default',
    'inverted',
    'info',
    'error',
    'success',
    'warning',
  ]),
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  badgeContent: PropTypes.string,
}

const Chip = ({ variant = 'default', text, onClick, badgeContent }) => (
  <Typography
    component={onClick ? 'a' : 'span'}
    onClick={onClick}
    sx={{
      ...styles[variant],
      ...styles.chip,
      ...(Boolean(onClick) ? styles.clickable : {}),
    }}
  >
    {text}
    {Boolean(badgeContent) && (
      <Box component='span' sx={styles.innerBadge}>
        {parseInt(badgeContent) > 99 ? '99+' : badgeContent}
      </Box>
    )}
  </Typography>
)

Chip.propTypes = chipPropTypes

export default Chip
