import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Button } from '@mui/material'

import * as PT from '../../../propTypes'
import { PageContent } from '../../../Layout'
import { HeroLogo, PayperBanner } from '../../../components'

const styles = {
  root: {
    mt: 0,
    mb: {
      xs: 3,
      md: 4,
    },
  },
  section: {
    width: `min(100%, var(--content-M-max-width))`,
    mx: 'auto',

    display: 'flex',
    flexDirection: 'column',
    rowGap: 1.5,
  },
  cta: {
    width: 'var(--button-max-width)',
    mx: 'auto',
  },
}

const Text = ({ text }) => (
  <Typography
    dangerouslySetInnerHTML={{
      __html: text,
    }}
    variant='body2'
  />
)

const EndOfServiceViewPropTypes = {
  customer: PT.Customer,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string,
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
}

const EndOfServiceView = ({ customer, action, text1, text2, onLoad }) => {
  useEffect(() => {
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageContent sx={styles.root}>
      {Boolean(customer) ? (
        <HeroLogo {...customer} className='full-bleed' isHeader />
      ) : (
        <PayperBanner className='full-bleed' />
      )}
      <Box sx={styles.section}>
        <Text text={text1} />
        {text2 && <Text text={text2} />}

        {Boolean(action) && (
          <Button fullWidth color='primary' onClick={action.onClick}>
            {action.label}
          </Button>
        )}
      </Box>
    </PageContent>
  )
}
EndOfServiceView.propTypes = EndOfServiceViewPropTypes

export default EndOfServiceView
