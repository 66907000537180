import { useState, useEffect } from 'react'
import { User } from '../../../../../business'

const useNewArticlesCount = (topicId) => {
  const [newArticlesCount, setNewArticlesCount] = useState()
  const topicArticlesData = User.useTopicArticlesQuery(topicId)

  const resetArticlesCount = () => {
    setNewArticlesCount(0)
    topicArticlesData.refetch()
  }

  useEffect(() => {
    if (topicArticlesData.isFetching) {
      setNewArticlesCount()
    } else {
      const newArticlesCount = topicArticlesData.data?.meta?.newArticles
      if (!!newArticlesCount) {
        setNewArticlesCount(newArticlesCount)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicArticlesData.isFetching])

  return { newArticlesCount, resetArticlesCount }
}

export default useNewArticlesCount
