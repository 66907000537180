import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

import * as PT from '../../../../propTypes'
import * as a9 from '../../../../../analytics'
import { Chip } from '../../../../components'
import useNewArticlesCount from './useNewArticlesCount'
import View from './View'

const TopicChip = ({ topic }) => {
  const navigate = useNavigate()
  const { newArticlesCount, resetArticlesCount } = useNewArticlesCount(topic.id)

  const handleClickTopic = (topic) => {
    a9.dispatchEvent(a9.EVENTS.PD_TOPIC_OF_INTEREST_CLICK, {
      topicId: topic.id,
    })
    navigate(`topic/${topic.id}/${encodeURIComponent(topic.name)}`, {
      state: {
        topicName: `${Boolean(topic.emoji) ? topic.emoji + ' ' : ''}${
          topic.name
        }`,
      },
    })
    resetArticlesCount()
  }

  return (
    <Chip
      key={topic.id}
      text={Boolean(topic.emoji) ? `${topic.emoji} ${topic.name}` : topic.name}
      variant='inverted'
      onClick={() => handleClickTopic(topic)}
      badgeContent={newArticlesCount}
    />
  )
}

const TopicsPropTypes = {
  data: PropTypes.arrayOf(PT.Topic).isRequired,
}

const Topics = ({ data }) => {
  useEffect(() => {
    a9.dispatchEvent(a9.EVENTS.PD_TOPIC_OF_INTEREST_SHOW)
  }, [])

  return (
    <View>
      {data.map((topic) => (
        <TopicChip key={topic.id} topic={topic} />
      ))}
    </View>
  )
  // return <View renderTopicChip={() => {}} />
}
Topics.propTypes = TopicsPropTypes

export default Topics
