import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { IMaskInput } from 'react-imask'

/**
 * https://imask.js.org/guide.html#masked-pattern
 *
 * Mask symbols definition:
 *  0 - any digit
 *  {} - include fixed part in unmasked value
 */

const PhoneMaskCustomInput = forwardRef((props, ref) => {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask='{(}{+}00{)} #00 000000'
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
      placeholder='(+34) XXX XXXXXX'
    />
  )
})

PhoneMaskCustomInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default PhoneMaskCustomInput
