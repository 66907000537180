import PropTypes from 'prop-types'
import { Box } from '@mui/material'

import { PageBlock } from '../../../../Layout'
import { useTexts } from '../../../../../texts'

const styles = {
  buttonsContainer: {
    width: 'min(100%, var(--content-L-max-width))',
    mx: 'auto',
    p: 0,

    display: 'flex',
    flexWrap: 'wrap',
    gap: { xs: 0.5, md: 1 },
  },
}

const TopicsViewPropTypes = {
  children: PropTypes.node,
}

const TopicsView = ({ children }) => {
  const texts = useTexts()

  return (
    <PageBlock title={texts.getTopicsTitle()}>
      <Box sx={styles.buttonsContainer} id='section_buttons'>
        {children}
      </Box>
    </PageBlock>
  )
}
TopicsView.propTypes = TopicsViewPropTypes

export default TopicsView
