import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import * as a9 from '../../../analytics'
import { URL_SEARCH_PARAMS_SOCIAL_LOGIN_KEYS } from './SocialLoginCallback/useSocialLoginCallback'
import { Auth, Session } from '../../../business'
import { useNavigateWithSearchParams } from '../../utils'
import { CircularProgress } from '../../components'
import useAccessParams from './useAccessParams'
import useAccessFinish from './useAccessFinish'

const getAuthCodeFromUrlParam = () => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  return urlSearchParams.get(
    URL_SEARCH_PARAMS_SOCIAL_LOGIN_KEYS.THIRD_PARTY_CODE,
  )
}

const dispatchAnalyticsSuccessEvent = (
  identityProvider,
  hasSystemUser,
  userSub,
) => {
  let name = ''
  if (identityProvider === Auth.AUTHORIZE_IDENTITY_PROVIDER.APPLE) {
    name = hasSystemUser
      ? a9.EVENTS.PA_FORM_SUBMIT_LOGIN_APPLE
      : a9.EVENTS.PA_FORM_SUBMIT_SIGNUP_APPLE
  } else if (identityProvider === Auth.AUTHORIZE_IDENTITY_PROVIDER.GOOGLE) {
    name = hasSystemUser
      ? a9.EVENTS.PA_FORM_SUBMIT_LOGIN_GOOGLE
      : a9.EVENTS.PA_FORM_SUBMIT_SIGNUP_GOOGLE
  }

  a9.dispatchEvent(name, {
    userSub,
    status: 'success',
  })
}

const SuccessThirdPartyIdpAccess = () => {
  const navigateWithSearchParams = useNavigateWithSearchParams()

  const {
    state: { identityProvider },
  } = useLocation()

  const accessParamsData = useAccessParams()
  const accessFinish = useAccessFinish(accessParamsData)

  useEffect(() => {
    ;(async () => {
      const code = getAuthCodeFromUrlParam()
      const tokens = await Auth.getJWTs(code)

      const { hasSystemUser } = await Auth.verifyCompleteRegistration(
        tokens.accessToken,
      )
      if (!hasSystemUser) {
        if (
          !Session.isSearchParamStored(
            Session.STORED_URL_PARAMS.USER_LICENCE_TOKEN,
          ) ||
          !accessParamsData?.hasLicenceCreationToken
        ) {
          await Auth.removeIdpAccountInCognito(tokens)
          navigateWithSearchParams('/access', {
            state: { referredRegistrationError: true },
          })
          return
        } else {
          await Auth.registerIdpAccountInTransactionalDB(
            tokens,
            accessParamsData?.attribution,
          )
        }
      }

      Session.storeSession(tokens)

      dispatchAnalyticsSuccessEvent(
        identityProvider,
        hasSystemUser,
        Session.getUserSub(),
      )

      accessFinish.handleFinishAuth()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <CircularProgress height='80vh' />
}

export default SuccessThirdPartyIdpAccess
