import REDSYS_DS_RESPONSE_MESSAGE_FOR_CODE from './REDSYS_DS_RESPONSE_MESSAGE_FOR_CODE.json'

const REDSYS_DSRESP_CODE_MESSAGE_PATTERN = /Code (\d+)/

export const getRedsysDSResponseMessageTranslation = (redsysMessage) => {
  let errorMessage = null

  const codeNumberMatches = redsysMessage.match(
    REDSYS_DSRESP_CODE_MESSAGE_PATTERN,
  )

  if (codeNumberMatches && codeNumberMatches.length > 1) {
    const codeNumber = codeNumberMatches[1]
    if (Object.keys(REDSYS_DS_RESPONSE_MESSAGE_FOR_CODE).includes(codeNumber)) {
      errorMessage = `Error durante la operación: ${REDSYS_DS_RESPONSE_MESSAGE_FOR_CODE[codeNumber]}`
    }
  }
  return errorMessage
}

export class RedsysError extends Error {
  constructor(message) {
    super(message)
    this.name = 'RedsysError'
  }
}
