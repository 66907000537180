import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

import { useTexts } from '../../../../texts'
import { Logout, PayperBanner, CircularProgress } from '../../../components'
import { PageContent } from '../../../Layout'
import * as PT from '../../../propTypes'
import styles from './account.styles'
import PersonalDataCard from './PersonalDataCard'
import LicenceCard from './LicenceCard'

const Section = ({ title, children, sx = {} }) => {
  return (
    <Box sx={sx}>
      <Typography component='h2' variant='h4'>
        {title}
      </Typography>
      <Box sx={styles.sectionContent}>{children}</Box>
    </Box>
  )
}

const AccountPropTypes = {
  userData: PT.UserData,
  licenceState: PT.UserLicenceState,
  onClickUpdatePersonalData: PropTypes.func,
  onClickUpdatePassword: PropTypes.func,
  onClickLogout: PropTypes.func,
}

const Account = ({
  userData,
  licenceState,
  onClickUpdatePersonalData,
  onClickUpdatePassword,
  onClickLogout,
}) => {
  const texts = useTexts()

  if (licenceState.isLoading) return <CircularProgress height='100vh' />

  return (
    <PageContent sx={{ mt: 0 }}>
      <PayperBanner className='full-bleed' />
      <Box sx={styles.root}>
        {Boolean(licenceState?.data) && (
          <Section title={texts.yourAccess()}>
            <LicenceCard
              expirationDate={licenceState?.data?.licence?.expirationDate}
              clientName={licenceState?.data?.customer?.name}
            />
          </Section>
        )}

        {Boolean(userData) && (
          <Section title={texts.personalData()}>
            <PersonalDataCard
              userData={userData}
              onClickUpdatePassword={onClickUpdatePassword}
              onClickUpdatePersonalData={onClickUpdatePersonalData}
            />
          </Section>
        )}
      </Box>
      <Logout onClickLogout={onClickLogout} />
    </PageContent>
  )
}

Account.propTypes = AccountPropTypes

export default Account
