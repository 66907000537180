import { useQuery } from '@tanstack/react-query'

import { getLicenceById } from './service'

const useLicenceQuery = (licenceId) =>
  useQuery({
    queryKey: ['licence', licenceId],
    queryFn: async () => await getLicenceById(licenceId),
    enabled: !!licenceId,
    staleTime: 5 * 60 * 1000,
  })

export default useLicenceQuery
