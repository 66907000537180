import PropTypes from 'prop-types'
import { Box, Typography, Link as MUILink } from '@mui/material'

import { Session } from '../../../business'
import { useTexts } from '../../../texts'
import { Icons } from '../../../assets'

import styles from './alert.styles'

const isDashboardPage = (pathname) => pathname.includes('/dashboard')

const DASHBOARD_SUBROUTE_LINK_PROPS = { href: '#private-contact-form' }
const DASHBOARD_LINK_PROPS = {
  href: 'dashboard#private-contact-form',
  target: '_blank',
  rel: 'noopener',
}
const PUBLIC_PAGE_LINK_PROPS = {
  href: '/#public-contact-form',
  target: '_blank',
  rel: 'noopener',
}

const alertPropTypes = {
  variant: PropTypes.oneOf(['info', 'error', 'success', 'warning']).isRequired,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  sx: PropTypes.object,
  withContactLink: PropTypes.bool,
  dataTest: PropTypes.string,
}

const Alert = ({ variant, title, text, withContactLink, sx, dataTest }) => {
  const texts = useTexts()

  const linkProps = isDashboardPage(window.location.pathname)
    ? DASHBOARD_SUBROUTE_LINK_PROPS
    : Session.isAuthenticated()
    ? DASHBOARD_LINK_PROPS
    : PUBLIC_PAGE_LINK_PROPS

  return (
    <Box
      sx={{ ...styles.root, ...styles[variant], ...sx }}
      data-test={dataTest}
    >
      <Box sx={styles.icon}>
        {variant === 'success' ? (
          <Icons.Check />
        ) : variant === 'info' ? (
          <Icons.ExclamationCircle />
        ) : (
          <Icons.ExclamationTriangle />
        )}
      </Box>
      <Box sx={styles.content}>
        {title ? <Typography sx={styles.title}>{title}</Typography> : null}
        <Typography sx={styles.text}>{text}</Typography>
        {Boolean(withContactLink) && (
          <Typography sx={styles.text}>
            <MUILink {...linkProps} sx={styles.link}>
              {texts.getContactUsOnPersistentError1()}
            </MUILink>
            {texts.getContactUsOnPersistentError2()}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

Alert.defaultProps = {
  variant: 'info',
}

Alert.propTypes = alertPropTypes

export default Alert
