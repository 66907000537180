import { Navigate } from 'react-router-dom'

import * as a9 from '../../../analytics'
import { useNavigateWithSearchParams } from '../../utils'
import { Public } from '../../../business'
import { CircularProgress } from '../../components'
import useMediaButtons from '../useMediaButtons'
import {
  getLicenceIdFromUrl,
  default as useAccessParams,
} from '../AccessPages/useAccessParams'

import View from './View'

const getMediaListForCustomer = (customer, newspapers, magazines) => {
  if (!!customer?.configuration?.woMagazines) {
    return newspapers
  } else {
    return newspapers.concat(magazines)
  }
}

const LandingCustomer = ({ licenceId }) => {
  const navigateWithSearchParams = useNavigateWithSearchParams()

  const licenceQuery = Public.useLicenceQuery(licenceId)
  const { coreNewspapers, coreMagazines } = useMediaButtons()
  const accessParamsData = useAccessParams()

  if (licenceQuery.isPending) return <CircularProgress />
  if (licenceQuery.isError || !licenceQuery.data?.customer?.imageUrl) {
    return <Navigate to={'/access' + window.location.search} replace />
  }

  const analyticsParams = {
    customerId: licenceQuery?.data?.customer?.id,
    licenceId: licenceQuery?.data?.id,
    utm: accessParamsData?.attribution?.utm,
  }

  const handleClickContinue = () => {
    a9.dispatchEvent(
      a9.EVENTS.PP_CLICK_LANDING_CUSTOMER_CONTINUE,
      analyticsParams,
    )
    navigateWithSearchParams('/access')
  }

  return (
    <View
      media={getMediaListForCustomer(
        licenceQuery.data.customer,
        coreNewspapers,
        coreMagazines,
      )}
      customer={licenceQuery.data.customer}
      onClickContinue={handleClickContinue}
      onLoad={() => {
        a9.dispatchEvent(
          a9.EVENTS.PA_PAGE_VIEW_LANDING_CUSTOMER,
          analyticsParams,
        )
      }}
    />
  )
}

const withLicenceId = (Component) => (props) => {
  const licenceId = getLicenceIdFromUrl()

  if (licenceId === null) {
    return <Navigate to={'/access' + window.location.search} replace />
  } else {
    return <Component {...props} licenceId={licenceId} />
  }
}

export default withLicenceId(LandingCustomer)
