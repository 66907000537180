import { useLocation, Navigate } from 'react-router-dom'
import { useState, useEffect } from 'react'

import { Session, User, Public } from '../business'
import { CircularProgress } from './components'

const RequireActiveLicence = ({ children }) => {
  let location = useLocation()
  const licenceState = User.useLicence()

  if (licenceState.isLoading) return <CircularProgress />
  if (
    location.pathname !== '/end-of-service' &&
    licenceState?.data?.isEndOfService
  ) {
    return <Navigate to='/end-of-service' state={{ from: location }} replace />
  }
  return children
}

const RequireAuthenticated = ({ children }) => {
  let location = useLocation()
  const [isRefreshingSession, setIsRefreshingSession] = useState(true)

  Session.storeSearchParam(Session.STORED_URL_PARAMS.USER_LICENCE_TOKEN)

  useEffect(() => {
    ;(async () => {
      if (Session.isAuthenticated() && Session.shouldBeRefreshed()) {
        await Session.refreshSession()
        setIsRefreshingSession(false)
      }
      setIsRefreshingSession(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isRefreshingSession) return <CircularProgress />
  if (!Session.isAuthenticated()) {
    return <Navigate to='/' state={{ from: location }} replace />
  }
  return (
    <Public.ArticlesSectionsProvider>
      <Public.MediaProvsProvider>
        <User.LicenceProvider>
          <RequireActiveLicence>{children}</RequireActiveLicence>
        </User.LicenceProvider>
      </Public.MediaProvsProvider>
    </Public.ArticlesSectionsProvider>
  )
}

export default RequireAuthenticated
