import PropTypes from 'prop-types'

import * as PT from '../../../propTypes'
import EmailStep from './EmailStep'
import PasswordStep from './PasswordStep'
import UserDataStep from './UserDataStep'
import { STEPS, EMAIL_STEP_VARIANTS } from './useAccess'

const acessStepsPropTypes = {
  customer: PT.Customer,
  variant: PropTypes.oneOf(Object.values(EMAIL_STEP_VARIANTS)),
  registerEmail: PropTypes.string,
  loginEmail: PropTypes.string,
  resetAccess: PropTypes.func,
  handleSubmitEmail: PropTypes.func,
  handleFinishAuth: PropTypes.func,
  attribution: PropTypes.object,
  analyticsParams: PropTypes.object,
  handleGoToForgottenPassword: PropTypes.func,
  activeStep: PropTypes.oneOf(Object.values(STEPS)),
}

const AccessSteps = ({
  variant,
  customer,
  registerEmail,
  loginEmail,
  resetAccess,
  handleSubmitEmail,
  handleFinishAuth,
  attribution,
  analyticsParams,
  handleGoToForgottenPassword,
  activeStep,
}) => (
  <>
    {activeStep === STEPS.USER_DATA ? (
      <UserDataStep
        email={registerEmail}
        withGeneratedPassword={
          !Boolean(customer?.configuration?.emailValidation)
        }
        onChangeEmail={resetAccess}
        onContinue={(userData) => handleFinishAuth(userData)}
        attribution={attribution}
        analyticsParams={analyticsParams}
      />
    ) : activeStep === STEPS.PASSWORD ? (
      <PasswordStep
        email={loginEmail}
        onGoToForgottenPassword={handleGoToForgottenPassword}
        onContinue={(userData) => handleFinishAuth(userData)}
        analyticsParams={analyticsParams}
      />
    ) : (
      <EmailStep
        variant={variant}
        customer={customer}
        onSubmitEmail={handleSubmitEmail}
        analyticsParams={analyticsParams}
      />
    )}
  </>
)
AccessSteps.propTypes = acessStepsPropTypes

export default AccessSteps
