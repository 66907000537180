import { useMediaQuery, useTheme } from '@mui/material'
import { useEffect } from 'react'

import { User } from '../../../../business'
import * as a9 from '../../../../analytics'
import { PageContent, WhiteBckgFullWidth } from '../../../Layout'
import { PAGE_CONTENT_MARGINS } from '../../../Layout/PageContent'
import { HeroLogo } from '../../../components'
import AssociatedMediaDynamicList from './AssociatedMediaDynamicList'
import Search from './Search'
import Sections from './Sections'
import AddToHomeScreenWizard from './AddToHomeScreenWizard'
import Topics from './Topics'
import Magazines from './Magazines'

const Discovery = () => {
  const licenceState = User.useLicence()

  return (
    <View
      customerData={{ ...licenceState, data: licenceState.data?.customer }}
      onLoad={() => {
        a9.dispatchEvent(a9.EVENTS.PD_PAGE_VIEW_DISCOVERY)
      }}
    />
  )
}

export const View = ({ customerData, withoutAddToHomeWizard, onLoad }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageContent sx={{ mt: 0 }}>
      <WhiteBckgFullWidth sx={{ py: PAGE_CONTENT_MARGINS.mt }}>
        {!customerData.isLoading && Boolean(customerData?.data?.imageUrl) && (
          <HeroLogo
            {...customerData.data}
            isHeader={!isDesktop}
            className='mobile-full-bleed'
            sx={{ mt: { xs: -2, md: 0 } }}
          />
        )}
        <Search />
      </WhiteBckgFullWidth>
      {!customerData.isLoading &&
        Boolean(customerData?.data?.topics?.length) && (
          <Topics data={customerData.data.topics} />
        )}
      <AssociatedMediaDynamicList />
      {!Boolean(customerData?.data?.configuration?.woMagazines) && (
        <Magazines />
      )}
      {!withoutAddToHomeWizard && <AddToHomeScreenWizard />}
      <Sections />
    </PageContent>
  )
}

export default Discovery
