import PropTypes from 'prop-types'

import * as PT from '../../../propTypes'
import { CircularProgress } from '../../../components'
import useAccess from './useAccess'
import Modal from './Modal'
import Steps from './Steps'

export const variantPropType = PropTypes.oneOf([
  { variant: 'STANDARD' },
  { variant: 'PROMOTION', customer: PT.Customer },
  { variant: 'CUSTOMER_MANDATORY_WARNING' },
  { variant: 'CUSTOMER_REGISTRATION_ERROR' },
])

const Access = () => {
  const { handleCloseButton, isLoading, ...stepsProps } = useAccess()

  if (isLoading) return <CircularProgress />
  return (
    <Modal
      onCancel={handleCloseButton}
      customer={stepsProps?.customer}
      contentId={`${stepsProps.activeStep}-step`}
    >
      <Steps {...stepsProps} />
    </Modal>
  )
}

export default Access
