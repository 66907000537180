import { useNavigate } from 'react-router-dom'

import { AUTHENTICATED_DEFAULT_ROUTE } from '../../constants'

const useAccessFinish = (accessParamsData) => {
  const navigate = useNavigate()

  const handleFinishUnauth = () => {
    if (Boolean(accessParamsData.redirectOnUnauthUri)) {
      window.location = accessParamsData.redirectOnUnauthUri
    } else {
      navigate('/' + window.location.search)
    }
  }

  const handleFinishAuth = () => {
    if (Boolean(accessParamsData.redirectOnAuthUri)) {
      window.location = accessParamsData.redirectOnAuthUri
    } else {
      navigate(AUTHENTICATED_DEFAULT_ROUTE)
    }
  }

  return {
    handleFinishUnauth,
    handleFinishAuth,
  }
}

export default useAccessFinish
