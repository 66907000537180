import * as Session from './session'

export class InvalidTokenError extends Error {
  constructor(message) {
    super(message)
    this.name = 'InvalidTokenError'
  }
}

const buildHeaders = () => ({
  Identity: Session.getIdToken(),
  Authorization: `Bearer ${Session.getAccessToken()}`,
})

const fetchWithAuthorization = (fetchFn) =>
  async function () {
    try {
      if (Session.shouldBeRefreshed()) {
        await Session.refreshSession()
      }
      return await fetchFn(buildHeaders(), ...arguments)
    } catch (error) {
      if (error instanceof InvalidTokenError) {
        const refreshToken = Session.getRefreshToken()

        if (!refreshToken) {
          Session.logout()
        } else {
          await Session.refreshSession()
          return await fetchFn(buildHeaders(), ...arguments)
        }
      } else {
        throw error
      }
    }
  }

export default fetchWithAuthorization
