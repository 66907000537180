const styles = {
  root: (theme) => ({
    width: `min(100%, var(--content-M-max-width))`,
    mx: 'auto',

    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: (theme) => theme.spacing(1),
    gridRowGap: (theme) => theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridColumnGap: (theme) => theme.spacing(2),
    },
  }),
  media: {
    height: 50,
    p: (theme) => ({
      xs: theme.spacing(1),
    }),

    backgroundColor: 'neutral.white',
    boxShadow: 2,

    display: 'flex',
    justifyContent: 'center',

    '& img': {
      maxWidth: '100%',
      // NOTE: This max-height has been fixed due to strange resizing issues
      // experienced in iPhone browsers (Chrome & Safari) when button-state changes
      maxHeight: 24.4,
      width: 'auto',
      height: 'auto',
      objectFit: 'contain',
      display: 'block',
    },

    '&.button': {
      '&:hover': {
        backgroundColor: 'neutral.light',
        boxShadow: 2,
      },
      '&:active': {
        boxShadow: 0,
      },
    },
    '&.logo': {
      borderRadius: '8px',
    },
  },
  mediaLowVisibility: {
    p: (theme) => ({
      xs: theme.spacing(0.8),
    }),
  },
}

export default styles
