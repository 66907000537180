import { useEffect } from 'react'
import { Box, Typography, Button, useTheme } from '@mui/material'

import { useTexts } from '../../../texts'
import { Icons } from '../../../assets'

import { AssociatedMedia, HeroLogo } from '../../components'
import { PageContent } from '../../Layout'
import styles from './landingCustomer.styles'

const LandingCustomerView = ({
  customer,
  media,
  onClickContinue,
  withBenefitsInfo,
  onLoad,
}) => {
  const texts = useTexts()
  const theme = useTheme()

  const BENEFITS_TEXTS = [
    {
      title: texts.getSummaryTitle(),
      text: texts.getSummaryClaim(),
      Icon: Icons.Sparkles,
    },
    {
      title: texts.getCoverageTitle(),
      text: texts.getCoverageClaim(),
      Icon: Icons.Coverage,
    },
    {
      title: texts.getContextTitle(),
      text: texts.getContextClaim(),
      Icon: Icons.Context,
    },
  ]

  useEffect(() => {
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageContent sx={styles.root}>
      <Box sx={styles.mainContent}>
        <HeroLogo {...customer} className='full-bleed' isHeader />
        <Typography
          dangerouslySetInnerHTML={{
            __html:
              customer?.configuration?.claim ??
              texts.getCustomerBenefitsClaim(customer.name),
          }}
          variant='body2'
          sx={styles.claim}
        />
        <Box sx={styles.medias}>
          <AssociatedMedia media={media} onClickMedia={onClickContinue} />
        </Box>

        <Box sx={styles.cta}>
          <Button
            fullWidth
            color='primary'
            onClick={onClickContinue}
            data-test='continue-btn'
          >
            {texts.getRegisterContinueAction()}
          </Button>
        </Box>
      </Box>

      {withBenefitsInfo && (
        <Box sx={styles.section}>
          <Typography variant='body2'>{texts.getBenefitsClaim()}</Typography>
          <Box sx={styles.benefitsList}>
            {BENEFITS_TEXTS.map(({ title, text, Icon }) => (
              <Box sx={styles.benefitsItem} key={title}>
                <Box className='icon'>
                  <Icon color={theme.palette.neutral.dark} />
                </Box>
                <Box className='text'>
                  <Typography variant='h6'>{title}</Typography>
                  <Typography variant='body2'>{text}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <Typography variant='body2'>
            {texts.getContinueToRegistrationClaim()}
          </Typography>
          <Box sx={styles.cta}>
            <Button fullWidth color='secondary' onClick={onClickContinue}>
              {texts.getRegisterContinueAction2()}
            </Button>
          </Box>
        </Box>
      )}
    </PageContent>
  )
}

export default LandingCustomerView
