import { useEffect } from 'react'
import PropTypes from 'prop-types'

import * as a9 from '../../../../../analytics'
import useUserDataForm from './useUserDataForm'
import View from './View'

const FORM_ID = a9.EVENTS.PA_FORM_SUBMIT_SIGNUP

const userDataStepPropTypes = {
  email: PropTypes.string.isRequired,
  withGeneratedPassword: PropTypes.bool,
  onChangeEmail: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  attribution: PropTypes.object,
  analyticsParams: PropTypes.object,
}

const UserDataStep = ({
  email,
  withGeneratedPassword,
  onContinue,
  onChangeEmail,
  attribution = {},
  analyticsParams,
}) => {
  const { status, submitForm, updateLegalCheckbox, ctaLabel } = useUserDataForm(
    FORM_ID,
    attribution,
    { withGeneratedPassword },
  )

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    submitForm(formValues)
  }

  useEffect(() => {
    if (status.result !== null) {
      onContinue(status.result)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  useEffect(() => {
    a9.dispatchEvent(a9.EVENTS.PA_PAGE_VIEW_SIGNUP, analyticsParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <View
      email={email}
      withGeneratedPassword={withGeneratedPassword}
      formId={FORM_ID}
      onSubmit={handleSubmit}
      onChangeControlledLegalCheckbox={updateLegalCheckbox}
      ctaLabel={ctaLabel}
      {...status}
      onChangeEmail={onChangeEmail}
    />
  )
}

UserDataStep.propTypes = userDataStepPropTypes

export default UserDataStep
