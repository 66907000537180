import { useNavigate } from 'react-router-dom'

import { User, Session } from '../../../../business'
import * as a9 from '../../../../analytics'
import View from './View'

const AccountPage = () => {
  const navigate = useNavigate()

  const licenceState = User.useLicence()

  const {
    state: { data: userData },
  } = User.usePersonalData()

  const onClickUpdatePersonalData = () => {
    navigate('update-personal-data')
  }
  const onClickUpdatePassword = () => {
    navigate('update-password-with-code')
  }

  const handleLogout = () => {
    a9.dispatchEvent(a9.EVENTS.PD_CLICK_LOGOUT)
    Session.logout()
  }

  return (
    <View
      userData={userData}
      licenceState={licenceState}
      onClickUpdatePersonalData={onClickUpdatePersonalData}
      onClickUpdatePassword={onClickUpdatePassword}
      onClickLogout={handleLogout}
    />
  )
}

export default AccountPage
