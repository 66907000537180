import PropTypes from 'prop-types'
import {
  Typography,
  CircularProgress,
  InputAdornment,
  Box,
  Link,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import {
  Alert,
  FormSkinWrapper,
  TextField,
  Decorator,
} from '../../../../components'
import { Icons } from '../../../../../assets'
import { useTexts } from '../../../../../texts'
import SocialLoginButtons from '../../SocialLoginButtons'
import { EMAIL_STEP_VARIANTS } from '../useAccess'
import styles from '../access.styles'

const emailStepViewPropTypes = {
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  formError: PropTypes.string.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  onClickGoogle: PropTypes.func.isRequired,
  onClickApple: PropTypes.func.isRequired,
  withoutSocialLogin: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(EMAIL_STEP_VARIANTS)),
}

const MailTo = ({ children }) => (
  <Link href={`mailto:${children}`}>{children}</Link>
)

const Title = ({ variant, customerName, customClaim }) => {
  const texts = useTexts()

  switch (variant) {
    case EMAIL_STEP_VARIANTS.PROMOTION:
      return (
        <Typography
          sx={styles.headingText}
          dangerouslySetInnerHTML={{
            __html:
              customClaim ?? texts.getAccessWithPromotionTitle(customerName),
          }}
          data-test='promotion-claim'
        />
      )
    case EMAIL_STEP_VARIANTS.CUSTOMER_MANDATORY_WARNING:
      return (
        <Typography
          sx={styles.headingText}
          dangerouslySetInnerHTML={{
            __html: texts.getAccessWithInvitationWarning(),
          }}
          data-test='partner-mandatory-claim'
        />
      )
    case EMAIL_STEP_VARIANTS.CUSTOMER_REGISTRATION_ERROR:
      return (
        <Box sx={styles.headingText} data-test='registration-error-warning'>
          <Alert variant='error' text={texts.getAccessWithInvitationError1()} />
          <br></br>
          <Typography>
            {texts.getAccessWithInvitationError2()}
            <MailTo>info@bepayper.com</MailTo>
            {texts.getAccessWithInvitationError3()}
          </Typography>
        </Box>
      )
    default:
      return (
        <Typography id='dialogTitle' sx={styles.heading}>
          {texts.getAccessTitle()}
        </Typography>
      )
  }
}

const EmailStepView = ({
  formId,
  variant = EMAIL_STEP_VARIANTS.STANDARD,
  customerName,
  customClaim,
  onSubmit,
  isSubmitting,
  formError,
  fieldErrors,
  onClickGoogle,
  onClickApple,
  withoutSocialLogin,
}) => {
  const texts = useTexts()
  const isRegistrationError =
    variant === EMAIL_STEP_VARIANTS.CUSTOMER_REGISTRATION_ERROR

  return (
    <>
      <Title
        variant={variant}
        customerName={customerName}
        customClaim={customClaim}
      />
      {!Boolean(withoutSocialLogin) && (
        <>
          <SocialLoginButtons
            onClickGoogle={onClickGoogle}
            onClickApple={onClickApple}
            disabled={isSubmitting || isRegistrationError}
          />
          <Decorator />
        </>
      )}
      <form id={formId} onSubmit={onSubmit} noValidate>
        <FormSkinWrapper skin='light' sx={styles.form}>
          <TextField
            data-test='email-input'
            id='email'
            name='email'
            label={texts.getLoginFormNameLabel()}
            type='email'
            autoComplete='email'
            InputProps={{
              readOnly: isRegistrationError,

              startAdornment: (
                <InputAdornment position='start'>
                  <Icons.Envelope />
                </InputAdornment>
              ),
            }}
            helperText={fieldErrors.email}
            error={Boolean(fieldErrors.email)}
            inputProps={{ inputMode: 'email' }}
            required
          />
          {Boolean(formError) ? (
            <Alert
              variant='error'
              text={formError}
              dataTest='email-step-error'
            />
          ) : null}
          <LoadingButton
            data-test='submit-btn'
            color='primary'
            type='submit'
            fullWidth
            loading={isSubmitting}
            disabled={isSubmitting || isRegistrationError}
            loadingIndicator={<CircularProgress size={16} />}
            sx={styles.ctaBicolorLabel}
          >
            {texts.getContinueLabel()}
          </LoadingButton>
        </FormSkinWrapper>
      </form>
    </>
  )
}

EmailStepView.propTypes = emailStepViewPropTypes

export default EmailStepView
