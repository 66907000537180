const PAYPER_DOMAIN =
  process.env.NODE_ENV === 'development' ? 'localhost' : '.bepayper.com'
const DEFAULT_PATH = '/'
const DEFAULT_DAYS_TO_EXPIRATION = 30 * 3

export const DEFAULT_COOKIE_CONFIG = {
  daysToExpire: DEFAULT_DAYS_TO_EXPIRATION,
  path: DEFAULT_PATH,
  domain: PAYPER_DOMAIN,
}

export const ENDLESS_COOKIE_CONFIG = {
  ...DEFAULT_COOKIE_CONFIG,
  daysToExpire: undefined,
}

export const setCookie = (name, value, config = DEFAULT_COOKIE_CONFIG) => {
  let pathAttribute = ''
  if (config?.path) {
    pathAttribute = `; path=${config.path}`
  }

  let expiresAttribute = ''
  if (config?.daysToExpire) {
    const date = new Date()
    date.setTime(date.getTime() + config.daysToExpire * 24 * 60 * 60 * 1000)
    expiresAttribute = '; expires=' + date.toUTCString()
  }

  let maxAgeAttribute = ''
  if (config?.maxAgeInSeconds) {
    maxAgeAttribute = `; max-age=${config.maxAgeInSeconds}`
  }

  let domainAttribute = ''
  if (config?.domain) {
    domainAttribute = `; domain=${config.domain}`
  }

  document.cookie =
    name +
    '=' +
    encodeURIComponent(value) +
    domainAttribute +
    maxAgeAttribute +
    expiresAttribute +
    pathAttribute
}

export const getCookie = (cookieName) =>
  document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${cookieName}=`))
    ?.split('=')[1]
