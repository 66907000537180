import { useQuery } from '@tanstack/react-query'

import { getArticlesForTopic } from './service'

const INITIAL_PAGE = 1
const PAGE_SIZE = 20

const useTopicArticlesQuery = (
  topicId,
  page = INITIAL_PAGE,
  pageSize = PAGE_SIZE,
) => {
  const pagination = { limit: pageSize, offset: (page - 1) * pageSize }

  return useQuery({
    queryKey: ['topic', topicId, pagination],
    queryFn: async () => await getArticlesForTopic(topicId, pagination),
  })
}

export default useTopicArticlesQuery
