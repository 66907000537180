import { useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'

import * as a9 from '../../../../analytics'
import { Session, Public } from '../../../../business'
import { useNavigateWithSearchParams } from '../../../utils'
import useAccessFinish from '../useAccessFinish'
import useAccessParams, { getLicenceIdFromUrl } from '../useAccessParams'

export const STEPS = {
  EMAIL: 'email',
  PASSWORD: 'password',
  USER_DATA: 'user-data',
}

export const EMAIL_STEP_VARIANTS = {
  STANDARD: 'STANDARD',
  PROMOTION: 'PROMOTION',
  CUSTOMER_MANDATORY_WARNING: 'CUSTOMER_MANDATORY_WARNING',
  CUSTOMER_REGISTRATION_ERROR: 'CUSTOMER_REGISTRATION_ERROR',
}

const DEFATULT_VARIANT = EMAIL_STEP_VARIANTS.STANDARD

const useAccess = () => {
  const { state } = useLocation()
  const navigateWithSearchParams = useNavigateWithSearchParams()

  const licenceId = getLicenceIdFromUrl()
  const licenceQuery = Public.useLicenceQuery(licenceId)
  const customer = licenceQuery?.data?.customer

  const accessParamsData = useAccessParams()
  const accessFinish = useAccessFinish(accessParamsData)

  const [loginEmail, setLoginEmail] = useState(state?.loginEmail)
  const [registerEmail, setRegisterEmail] = useState()
  const [variant, setVariant] = useState(DEFATULT_VARIANT)

  const analyticsParams = {
    customerId: licenceQuery?.data?.customer?.id,
    licenceId: licenceQuery?.data?.id,
    utm: accessParamsData?.attribution?.utm,
  }

  const handleGoToForgottenPassword = () => {
    a9.dispatchEvent(a9.EVENTS.PA_CLICK_GO_TO_FP, analyticsParams)
    navigateWithSearchParams('/forgotten-password', {
      state: { from: 'access', loginEmail },
    })
  }

  const handleCloseButton = () => {
    a9.dispatchEvent(a9.EVENTS.PA_CLICK_CANCEL, analyticsParams)
    accessFinish.handleFinishUnauth('User cancelled the process')
  }

  const handleFinishAuth = (userData) => {
    Session.storeSession(userData)
    accessFinish.handleFinishAuth()
  }

  const setReferredRegistrationError = () => {
    setVariant(EMAIL_STEP_VARIANTS.CUSTOMER_REGISTRATION_ERROR)
    a9.dispatchEvent(
      a9.EVENTS.PA_PAGE_VIEW_REFERRED_REGISTRATION_ERROR,
      analyticsParams,
    )
  }

  const handleSubmitEmail = ({ isNewUser, email }) => {
    if (isNewUser) {
      if (!accessParamsData?.hasLicenceCreationToken) {
        setReferredRegistrationError()
      } else {
        setRegisterEmail(email)
      }
    } else {
      setLoginEmail(email)
    }
  }

  useEffect(() => {
    if (
      !!state?.referredRegistrationError &&
      variant !== EMAIL_STEP_VARIANTS.CUSTOMER_REGISTRATION_ERROR
    ) {
      setReferredRegistrationError()
      return
    }
    if (!accessParamsData?.hasLicenceCreationToken) {
      setVariant(EMAIL_STEP_VARIANTS.CUSTOMER_MANDATORY_WARNING)
    } else if (!!Boolean(customer)) {
      setVariant(EMAIL_STEP_VARIANTS.PROMOTION)
    } else {
      setVariant(DEFATULT_VARIANT)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  return {
    handleCloseButton,
    registerEmail,
    loginEmail,
    resetAccess: () => setRegisterEmail(),
    handleSubmitEmail,
    handleFinishAuth,
    handleGoToForgottenPassword,
    attribution: accessParamsData.attribution ?? {},
    variant,
    isLoading: licenceQuery.isLoading,
    customer,
    analyticsParams,
    activeStep: Boolean(registerEmail)
      ? STEPS.USER_DATA
      : Boolean(loginEmail)
      ? STEPS.PASSWORD
      : STEPS.EMAIL,
  }
}

export default useAccess
