import React from 'react'
import ReactDOM from 'react-dom/client'

import './analytics/eventsObservable'
import App from './App'
import { createDesignTokensCssVars } from './styles'

createDesignTokensCssVars()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
)
