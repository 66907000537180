import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import * as a9 from '../../../../analytics'
import { decode } from '../../../format'
import { Auth } from '../../../../business'
import { useTexts } from '../../../../texts'

export const URL_SEARCH_PARAMS_SOCIAL_LOGIN_KEYS = {
  THIRD_PARTY_CODE: 'code',
  THIRD_PARTY_ERROR: 'error',
  THIRD_PARTY_ERROR_DESC: 'error_description',
  STATE: 'state',
}

const EVENT_NAME_MAP = {
  [Auth.AUTHORIZE_IDENTITY_PROVIDER.APPLE]:
    a9.EVENTS.PA_FORM_SUBMIT_SIGNUP_APPLE,
  [Auth.AUTHORIZE_IDENTITY_PROVIDER.GOOGLE]:
    a9.EVENTS.PA_FORM_SUBMIT_SIGNUP_GOOGLE,
}

const ERROR_DESCRIPTION_MAP = {
  user_cancelled_authorize: 'El usuario ha cancelado el proceso de registro',
}

const useSocialLoginCallback = (redirectionPathOnSuccess) => {
  const texts = useTexts()
  const navigate = useNavigate()

  const [errorDescription, setErrorDescription] = useState()

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const error = urlSearchParams.get(
      URL_SEARCH_PARAMS_SOCIAL_LOGIN_KEYS.THIRD_PARTY_ERROR,
    )
    const errorDescription = urlSearchParams.get(
      URL_SEARCH_PARAMS_SOCIAL_LOGIN_KEYS.THIRD_PARTY_ERROR_DESC,
    )
    const encodedState = urlSearchParams.get(
      URL_SEARCH_PARAMS_SOCIAL_LOGIN_KEYS.STATE,
    )
    const { identityProvider, ...state } = decode(encodedState)

    if (!error) {
      const code = urlSearchParams.get(
        URL_SEARCH_PARAMS_SOCIAL_LOGIN_KEYS.THIRD_PARTY_CODE,
      )

      const mergedSearchParams = new URLSearchParams({
        ...state,
        code,
      }).toString()

      navigate(`${redirectionPathOnSuccess}?${mergedSearchParams}`, {
        state: { identityProvider },
      })
    } else {
      a9.dispatchEvent(EVENT_NAME_MAP[identityProvider], {
        status: 'error',
      })

      setErrorDescription(
        ERROR_DESCRIPTION_MAP[error] ??
          texts.getSomethingWentWrontErrorSubtitle(),
      )
      console.error(
        'Error on social login \n'.concat(
          `type: ${error}\n`,
          Boolean(errorDescription) ? errorDescription : '',
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { errorDescription }
}

export default useSocialLoginCallback
