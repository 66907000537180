import { eventsEndpoint } from '../config'
import { Session } from '../business'
import {
  getCookie,
  setCookie,
  ENDLESS_COOKIE_CONFIG,
} from '../business/cookies'
import * as config from '../config'

const ANALYTICS_COOKIE = '_ga'
const PAYPER_ANALYTICS_COOKIE = '_pa'

const generateClientId = () => {
  const part1 = Math.floor(Math.random() * 1000000000) // Random number up to 9 digits
  const part2 = Math.floor(Math.random() * 10000000000) // Random number up to 10 digits
  return `${part1}.${part2}`
}

const getClientIdAnalyticsCookie = () => {
  const rawGaValue = getCookie(ANALYTICS_COOKIE)
  if (Boolean(rawGaValue)) {
    return rawGaValue.split('.').slice(-2).join('.')
  }
  let payperClientId = getCookie(PAYPER_ANALYTICS_COOKIE)

  if (!payperClientId) {
    payperClientId = generateClientId()
    setCookie(PAYPER_ANALYTICS_COOKIE, payperClientId, ENDLESS_COOKIE_CONFIG)
  }
  return payperClientId
}

const sendPayperEventList = async (eventList) => {
  const clientId = getClientIdAnalyticsCookie()
  const response = await fetch(`${eventsEndpoint}/set-event`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: clientId,
      events: eventList,
    }),
  })
  if (!response.ok) {
    throw new Error()
  }
}

const sendPayperEvent = async (eventName, detail) => {
  try {
    await sendPayperEventList([
      {
        name: eventName,
        params: detail,
      },
    ])
  } catch (error) {
    console.error(`Payper: error sending event ${eventName}`)
  }
}

export const dispatchEvent = (eventName, params = {}) => {
  sendPayperEvent(eventName, {
    userSub: Session.getUserSub() ?? undefined,
    version: config.appVersion,
    // NOTE: do not move the params-info before the 'userSub' definition
    // 'params' object can contain userSub data before being available in session storage
    ...params,
  })
}

export * as EVENTS from './eventsNames.json'
export * as EVENTS_OLD from './eventsNamesOld.json'
