import { createContext, useContext, useReducer, useEffect } from 'react'

import { User, Session } from '../index'

const LicenceContext = createContext(null)

export const useLicence = () => {
  const context = useContext(LicenceContext)
  if (context === null) {
    throw new Error('useLicence must be used within a LicenceProvider')
  }
  return context
}

const QUERY_ACTIONS = {
  LOADING: 'LOADING',
  DATA: 'DATA',
  ERROR: 'ERROR',
}

const LOADING_STATE = {
  isLoading: true,
  data: undefined,
  error: false,
}

const getIsEndOfService = (data) => !Boolean(data?.licence?.isActive)

const licenceReducer = (state, action) => {
  switch (action.type) {
    case QUERY_ACTIONS.LOADING:
      return LOADING_STATE

    case QUERY_ACTIONS.DATA:
      return {
        ...state,
        isLoading: false,
        data: {
          ...action.payload,
          isEndOfService: getIsEndOfService(action.payload),
        },
        error: false,
      }

    case QUERY_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: { isEndOfService: false },
        error: true,
      }

    default:
      throw Error('Unknown action: ' + action.type)
  }
}

const LicenceProvider = ({ children }) => {
  const [licenceState, dispatchLicenceState] = useReducer(
    licenceReducer,
    LOADING_STATE,
  )

  const handleUserLicCreation = async () => {
    let createdUserLic = null
    const licenceTokenValue = Session.readStoredSearchParam(
      Session.STORED_URL_PARAMS.USER_LICENCE_TOKEN,
    )
    if (licenceTokenValue !== null) {
      try {
        const userLic = await User.createUserLicence(licenceTokenValue)
        if (!!userLic) {
          createdUserLic = userLic
        }
      } catch (error) {
        console.error(
          'Payper: an error occurred while the user-licence creation',
        )
      }
    }
    return createdUserLic
  }

  const fetchLicenceData = async () => {
    try {
      dispatchLicenceState({ type: QUERY_ACTIONS.LOADING })

      let userLicence = await handleUserLicCreation()
      if (userLicence === null) {
        userLicence = await User.fetchUserLicence()
      }

      dispatchLicenceState({
        type: QUERY_ACTIONS.DATA,
        payload: userLicence,
      })
    } catch (error) {
      dispatchLicenceState({ type: QUERY_ACTIONS.ERROR })
    }
  }

  useEffect(() => {
    ;(async () => await fetchLicenceData())()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LicenceContext.Provider value={licenceState}>
      {children}
    </LicenceContext.Provider>
  )
}

export default LicenceProvider
