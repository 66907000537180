import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

import { Icons } from '../../../../assets/index.js'
import { useTexts } from '../../../../texts.js'
import { formatShortDate, formatHour } from '../../../format.js'
import { Card } from '../../../components/index.js'

const styles = {
  expirationLabel: {
    '& > svg': {
      transform: 'translate(0, 2px)',
      mr: '3px',
    },
  },
}

const licenceCardPropTypes = {
  expirationDate: PropTypes.string,
  clientName: PropTypes.string,
}

const LicenceCard = ({ clientName, expirationDate }) => {
  const texts = useTexts()
  return (
    <Card
      variant='success'
      renderContent={() => (
        <>
          <Typography
            dangerouslySetInnerHTML={{
              __html: texts.getLicenceCardLabel(clientName),
            }}
          />

          {Boolean(expirationDate) && (
            <Typography className='highlighted' sx={styles.expirationLabel}>
              <Icons.Calendar />
              {texts.getExpirationLabel(
                formatShortDate(expirationDate),
                formatHour(expirationDate),
              )}
            </Typography>
          )}
        </>
      )}
    />
  )
}

LicenceCard.propTypes = licenceCardPropTypes

export default LicenceCard
