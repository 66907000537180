import { useTheme, useMediaQuery } from '@mui/material'

import { useTexts } from '../../../texts'
import { useNavigateWithSearchParams } from '../../utils'

import { AppBarNavigation, MobileBottomNavigation } from '../../components'
import useNavigation from './useNavigation'

const PrivateNavigation = ({ children }) => {
  const theme = useTheme()
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))
  const { actions, handleNavigation, handleGoHome, getActiveAction } =
    useNavigation()

  if (widerThanTablet) {
    return (
      <>
        <AppBarNavigation
          actions={actions}
          onClickMenuItem={handleNavigation}
          onClickHome={handleGoHome}
          activeAction={getActiveAction()}
        />
        {children}
      </>
    )
  } else {
    return (
      <>
        {children}
        <MobileBottomNavigation actions={actions} />
      </>
    )
  }
}

const PublicNavigation = ({ children }) => {
  const texts = useTexts()
  const navigateWithSearchParams = useNavigateWithSearchParams()

  return (
    <>
      <AppBarNavigation
        buttonActions={[
          {
            name: 'login',
            onClick: () => navigateWithSearchParams('/access'),
            label: texts.getLoginCtaAction(),
          },
        ]}
      />
      {children}
    </>
  )
}

const Navigation = ({ children, withoutHeader, isPublic }) => {
  if (Boolean(withoutHeader)) return children

  const Component = isPublic ? PublicNavigation : PrivateNavigation
  return <Component>{children}</Component>
}

export default Navigation
