import PropTypes from 'prop-types'
import { Box, IconButton, Typography, Dialog } from '@mui/material'

import { useTexts } from '../../../../texts'
import { Icons } from '../../../../assets'
import * as PT from '../../../propTypes'
import { HeroLogo } from '../../../components'

import styles from './access.styles.js'

const modalPropTypes = {
  customer: PT.Customer,
  background: PropTypes.oneOf(['solid', 'gradient', 'dark']),
  onCancel: PropTypes.func,
  children: PropTypes.node,
  contentId: PropTypes.string,
}

const Modal = ({
  customer,
  background = 'solid',
  onCancel,
  children,
  contentId,
}) => {
  const texts = useTexts()
  return (
    <Dialog open>
      {Boolean(onCancel) && (
        <Box sx={styles.closeButton}>
          <IconButton aria-label={texts.getCloseAction()} onClick={onCancel}>
            <Icons.Close />
          </IconButton>
        </Box>
      )}

      <Box id='modal' sx={{ ...styles.root, ...styles[background] }}>
        <Box id='modal-header' sx={styles.header}>
          {Boolean(customer?.imageUrl) ? (
            <HeroLogo
              {...customer}
              sx={{
                mb: { xs: 0.5, md: 1 },
                minHeight: (theme) => ({
                  xs: theme.spacing(7.625),
                  md: theme.spacing(6),
                }),
              }}
              isHeader
            />
          ) : (
            <Box sx={styles.logo}>
              <Icons.PayperLogo
                aria-label='Payper logo'
                color='#121212'
                height={40}
                width={190.58}
                viewBox='0 0 81 17'
              />
              <Typography variant='body2' sx={styles.text}>
                {texts.payperSlogan()}
              </Typography>
            </Box>
          )}
        </Box>

        <Box id='modal-content' sx={styles.content} data-test={contentId}>
          {children}
        </Box>
      </Box>
    </Dialog>
  )
}

Modal.propTypes = modalPropTypes

export default Modal
