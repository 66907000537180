export const variantStyles = (theme) => ({
  light: {
    '--text-color': theme.palette.neutral.darkest,
    '--highlighted-text-color': theme.palette.neutral.darkest,
    '--euro-text-color': 'var(--text-color)',
    '--bckg-color': theme.palette.neutral.white,
    '--icon-color': theme.palette.neutral.main,
  },
  light_error: {
    '--text-color': theme.palette.neutral.darkest,
    '--highlighted-text-color': theme.palette.error.main,
    '--euro-text-color': 'var(--text-color)',
    '--bckg-color': theme.palette.neutral.light,
    '--icon-color': theme.palette.error.main,
  },
  dark: {
    '--text-color': theme.palette.neutral.lightest,
    '--highlighted-text-color': theme.palette.neutral.lightest,
    '--euro-text-color': 'var(--text-color)',
    '--bckg-color': theme.palette.neutral.black,
    '--icon-color': theme.palette.neutral.main,
  },
  dark_error: {
    '--text-color': theme.palette.neutral.lightest,
    '--highlighted-text-color': theme.palette.error.main,
    '--euro-text-color': 'var(--highlighted-text-color)',
    '--bckg-color': theme.palette.error.dark,
    '--icon-color': theme.palette.error.main,
  },
  success: {
    '--text-color': theme.palette.neutral.darkest,
    '--highlighted-text-color': theme.palette.success.dark,
    '--euro-text-color': 'var(--highlighted-text-color)',
    '--bckg-color': theme.palette.success.light,
    '--icon-color': theme.palette.success.dark,
  },
})

const styles = {
  root: {
    '--card-width': { xs: '21.4375rem', md: '30rem' },
    width: { xs: 'min(100%, var(--card-width))', md: 'var(--card-width)' },
    minHeight: '7.75rem',

    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--spacing-l)',
    p: 'var(--spacing-l)',
    borderRadius: 'var(--corners-l)',
    boxShadow: 2,

    color: 'var(--text-color)',
    backgroundColor: 'var(--bckg-color)',
  },
  content: {
    display: 'flex',
    gap: 'var(--spacing-ml)',
  },
  mainContent: {
    overflowWrap: 'break-word' /* Handles breaking long words */,
    wordWrap: 'break-word' /* For backward compatibility with older browsers */,
    wordBreak: 'break-word',
    /* Ensures long words break at the container boundary */
    overflow: 'hidden' /* Prevents content from overflowing */,
    whiteSpace: 'normal' /* Allows text to wrap to the next line */,

    flexGrow: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--spacing-ml)',

    '& h4': {
      px: 0,
    },

    '& .euro': {
      color: 'var(--euro-text-color)',
      fontFamily: 'Inter',
      fontWeight: 'var(--typography-fontWeight-regular)',
      fontSize: 'var(--typography-typeScale-l)',
    },

    '& .highlighted': {
      color: 'var(--highlighted-text-color)',
    },
  },
  icon: {
    flexBasis: 'var(--size-iconXL)',
    '& svg': {
      height: 'var(--size-iconXL)',
      width: 'var(--size-iconXL)',
      color: 'var(--icon-color)',
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--spacing-ml)',
    '& button': {
      maxWidth: '100%',
    },
  },
}

export default styles
