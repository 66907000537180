const styles = {
  error: {
    '--chip-color': (theme) => theme.palette.error.lightest,
    '--chip-backgroundColor': (theme) => theme.palette.error.main,
    '--chip-hover-backgroundColor': (theme) => theme.palette.error.dark,
  },
  success: {
    '--chip-color': (theme) => theme.palette.neutral.white,
    '--chip-backgroundColor': (theme) => theme.palette.success.main,
    '--chip-hover-backgroundColor': (theme) => theme.palette.success.dark,
  },
  default: {
    '--chip-color': (theme) => theme.palette.neutral.dark,
    '--chip-backgroundColor': (theme) => theme.palette.neutral.light,
    '--chip-hover-backgroundColor': (theme) => theme.palette.neutral.lightest,
  },
  inverted: {
    '--chip-color': (theme) => theme.palette.neutral.black,
    '--chip-backgroundColor': (theme) => theme.palette.neutral.white,
    '--chip-hover-backgroundColor': (theme) => theme.palette.neutral.light,
  },
  warning: {
    '--chip-color': (theme) => theme.palette.neutral.black,
    '--chip-backgroundColor': (theme) => theme.palette.primary.main,
    '--chip-hover-backgroundColor': (theme) => theme.palette.warning.dark,
  },
  info: {
    '--chip-color': (theme) => theme.palette.info.lightest,
    '--chip-backgroundColor': (theme) => theme.palette.info.main,
    '--chip-hover-backgroundColor': (theme) => theme.palette.info.dark,
  },
  chip: {
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: 0.625,
    py: 0.25,
    px: 1,
    fontSize: (theme) => ({
      xs: theme.spacing(0.75),
      md: theme.spacing(0.75),
    }),
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: 1.5,
    letterSpacing: '0.1em',
    borderRadius: (theme) => theme.spacing(2),
    color: 'var(--chip-color)',
    backgroundColor: 'var(--chip-backgroundColor)',
    boxShadow: 2,
  },
  clickable: {
    cursor: 'pointer',
    '&:hover, &:active': {
      backgroundColor: 'var(--chip-hover-backgroundColor, inherit)',
    },
    '&:hover': {
      boxShadow: 2,
    },
    '&:active': {
      boxShadow: 0,
    },
  },
  innerBadge: {
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(231, 98, 69)',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    boxSizing: 'border-box',
    color: 'rgb(255, 255, 255)',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif',
    fontSize: '12px',
    fontWeight: 500,
    height: '20px',
    justifyContent: 'center',
    letterSpacing: '1.2px',
    lineHeight: '12px',
    minWidth: 'fit-content',
    paddingBottom: '0px',
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '0px',
    textSizeAdjust: '100%',
    textTransform: 'uppercase',
    width: '20px',
    zIndex: 1,
    WebkitBoxAlign: 'center',
    WebkitBoxPack: 'center',
    WebkitFontSmoothing: 'antialiased',
  },
}

export default styles
