import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Auth, Session } from '../../../business'
import { CircularProgress } from '../../components'
import { AUTHENTICATED_DEFAULT_ROUTE } from '../../constants'
import Error from '../Error'

const THIRD_PARTY_NAME = {
  RACE: 'race',
  PAYPER_ADMIN: 'payper-admin',
}

const AccessThirdParty = () => {
  const params = useParams()
  const navigate = useNavigate()

  const [error, setError] = useState()

  useEffect(() => {
    ;(async () => {
      const authenticateWithSSO =
        (fetchFn) =>
        async (...args) => {
          try {
            Session.clearSessionDataStorage()
            const authData = await fetchFn(...args)
            Session.storeSession(authData)
            navigate(AUTHENTICATED_DEFAULT_ROUTE)
          } catch (error) {
            if (error instanceof Auth.InvalidCredentialsError) {
              setError({ status: 401 })
            } else {
              setError({ status: 500 })
            }
          }
        }

      const searchParams = new URLSearchParams(window.location.search)

      if (params.thirdPartyName === THIRD_PARTY_NAME.RACE) {
        const userId = searchParams.get('id')
        const token = searchParams.get('token')

        if (!userId || !token) {
          setError({ status: 401 })
        } else {
          await authenticateWithSSO(Auth.authenticateRaceUser)(userId, token)
        }
      } else if (params.thirdPartyName === THIRD_PARTY_NAME.PAYPER_ADMIN) {
        const user = searchParams.get('user')
        if (!user) {
          setError({ status: 401 })
        } else {
          await authenticateWithSSO(Auth.authenticatePayperAdmin)(user)
        }
      } else {
        setError({ status: 500 })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  if (Boolean(error)) return <Error error={error} woPicture />
  return <CircularProgress height='80vh' />
}

export default AccessThirdParty
